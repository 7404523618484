import { LanguagesTypes } from '@glissandoo/lib/lang';
import antdCA from 'antd/lib/locale/ca_ES';
import caDateLocale from 'date-fns/locale/ca';
import set from 'lodash/set';
import messages from '../locales/ca.json';

const saLang = {
	messages,
	// Al utilizar el data-fns en DatePicker no tiene bien asignadas los nombres de los locales. Intenta cargar es_CA y no lo encuentra
	antd: set(antdCA, 'DatePicker.lang.locale', 'ca'),
	locale: LanguagesTypes.CA,
	dateFns: caDateLocale,
};

export default saLang;
