import {
	AuthErrors,
	AuthUserClaims,
	getRedirectSignIn,
} from '@glissandoo/lib/helpers/auth';
import { CollectionNames } from '@glissandoo/lib/helpers/collections';
import firebase from 'firebase/app';
import { auth } from 'functions/auth';
import { userDB } from 'functions/user';
import pick from 'lodash/pick';
import { getFirestoreViewHandler, ModuleViewActions } from 'modules/firestore';
import { PartnershipTypes } from 'modules/partnership/types';
import { ApplicationState } from 'modules/root';
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppTypes } from '../app/types';
import { AuthActions, AuthTypes, AuthUserDocData, UserParams } from './types';

type ThunkResult<R> = ThunkAction<R, ApplicationState, undefined, AuthActions>;

const formatUserView = (
	doc: firebase.firestore.DocumentSnapshot,
	state: ApplicationState
): AuthUserDocData => {
	const model = userDB.model(doc);
	const params = Object.values(UserParams);
	return pick(model, params);
};

const fetchUser =
	(action: ModuleViewActions, userId: string): ThunkResult<Promise<void>> =>
	async (dispatch, getState) => {
		const state = getState();

		const endpoint = firebase
			.firestore()
			.collection(CollectionNames.User)
			.doc(userId);

		await dispatch(
			getFirestoreViewHandler<AuthUserDocData>({
				action,
				view: state.auth.user,
				type: AuthTypes.SET_USER,
				endpoint,
				formatView: formatUserView,
			})
		);

		return Promise.resolve();
	};

const signInByEmailPassword = async (email: string, password: string) => {
	const { user } = await firebase
		.auth()
		.signInWithEmailAndPassword(email, password);

	if (!user) {
		throw new Error(AuthErrors.Unauthenticated);
	}
	return user;
};

const signInByToken = async () => {
	const idToken = await auth.status();
	if (!idToken) {
		throw new Error(AuthErrors.Unauthenticated);
	}

	const { user } = await firebase.auth().signInWithCustomToken(idToken);

	if (!user) {
		throw new Error(AuthErrors.Unauthenticated);
	}

	return user;
};

const firebaseAuthInit = async () =>
	process.env.NODE_ENV !== 'production'
		? signInByEmailPassword(
				process.env.REACT_APP_USER_EMAIL!,
				process.env.REACT_APP_USER_PASSWORD!
		  )
		: signInByToken();

const getClaims = async (user: firebase.User) => {
	const result = await user.getIdTokenResult();
	return result.claims as AuthUserClaims;
};

const checkPartnershipPermission = async (user: firebase.User) => {
	const claims = await getClaims(user);

	if (!claims.partnership) {
		throw new Error(AuthErrors.NoPartnerhispAdmin);
	}
};

const clearAll = () => (dispatch: Dispatch<any>) => {
	dispatch({ type: AuthTypes.CLEAR_ME });
	dispatch({ type: AppTypes.CLEAR_APP });
	dispatch({ type: PartnershipTypes.CLEAR_PARTNERSHIP });
};

const init = (): ThunkResult<Promise<firebase.User>> => async (dispatch) => {
	try {
		dispatch(clearAll());

		const user = await firebaseAuthInit();
		await checkPartnershipPermission(user);

		await dispatch(fetchUser(ModuleViewActions.INIT, user.uid));
		return user;
	} catch (error) {
		window.location.href = getRedirectSignIn({
			return_to: window.location.href,
			...(error instanceof Error && { error: error.message }),
		});
		return Promise.reject(error);
	}
};

export const authActions = {
	init,
};
