import { ModuleListActions, ModuleViewActions } from 'modules/firestore';
import { fetchPartnership, fetchPlans } from 'modules/partnership/actions';
import { getPartnership } from 'modules/partnership/selectors';
import { PartnershipDocData } from 'modules/partnership/types';
import { ApplicationState } from 'modules/root';
import { createContext, FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMount } from 'react-use';
import SkeletonLoading from './SkeletonLoading';

interface PartnershipProviderProps {
	id: string;
}

export const PartnershipContext =
	createContext<PartnershipDocData | null>(null);

export const PartnershipProvider: FC<PartnershipProviderProps> = ({
	children,
	id,
}) => {
	const dispatch = useDispatch();

	const partnership = useSelector((state: ApplicationState) =>
		getPartnership(state)
	);

	console.log(partnership);

	useMount(() => {
		console.log(id);
		dispatch(fetchPartnership(ModuleViewActions.INIT));
		dispatch(fetchPlans(ModuleListActions.INIT));
	});

	if (!partnership) {
		return <SkeletonLoading />;
	}

	// @TODO error

	return (
		<PartnershipContext.Provider value={partnership}>
			{children}
		</PartnershipContext.Provider>
	);
};

export const usePartnership = () => {
	const context = useContext(PartnershipContext);
	if (context === null) {
		throw new Error(
			`PartnershipContext must be used within a PartnershipProvider`
		);
	}
	return context;
};
