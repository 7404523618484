import { LanguagesTypes } from '@glissandoo/lib/lang';
import { ConfigProvider } from 'antd';
import get from 'lodash/get';
import { ApplicationState } from 'modules/root';
import { FC, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import locales, { getLocale } from './config';

const AppLanguage: FC = ({ children }) => {
	const dispatch = useDispatch();

	const [cookies] = useCookies(['lang']);
	const cookieLang: LanguagesTypes | undefined =
		cookies.lang === 'undefined' ? undefined : cookies.lang;

	const userLang = useSelector(({ auth }: ApplicationState) =>
		auth.user.data ? auth.user.data.language : undefined
	);

	// const browserLanguage = navigator.language.split('-');

	const currentLang = getLocale(cookieLang || userLang);
	const currentLocale = get(locales, currentLang.locale);

	useEffect(() => {
		dispatch({
			type: 'SET_LANG',
			payload: currentLang.locale,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentLang]);

	return (
		<ConfigProvider locale={currentLocale.antd}>
			<IntlProvider
				locale={currentLocale.locale}
				messages={currentLocale.messages}
			>
				{children}
			</IntlProvider>
		</ConfigProvider>
	);
};

export default AppLanguage;
