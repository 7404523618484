import update from 'immutability-helper';
import {
	FetchListState,
	FetchViewState,
	initalListState,
	initialViewState,
} from 'modules/firestore';
import { Reducer } from 'redux';
import {
	PartnerDocData,
	PartnershipAction,
	PartnershipDocData,
	PartnershipTypes,
	PlanDocData,
} from './types';

export interface PartnershipState {
	view: FetchViewState<PartnershipDocData>;
	plans: FetchListState<PlanDocData>;
	partners: FetchListState<PartnerDocData>;
}

export const initialState: PartnershipState = {
	view: initialViewState,
	plans: initalListState,
	partners: initalListState,
};

const reducer: Reducer<PartnershipState, PartnershipAction> = (
	state = initialState,
	action
) => {
	switch (action.type) {
		case PartnershipTypes.SET_VIEW_PARTNERSHIP: {
			return update(state, {
				view: { $merge: action.payload.value },
			});
		}

		case PartnershipTypes.UPDATE_LIST_PARTNERS: {
			return update(state, { partners: { $merge: action.payload } });
		}

		case PartnershipTypes.UPDATE_LIST_PLANS: {
			return update(state, { plans: { $merge: action.payload } });
		}

		case PartnershipTypes.CLEAR_PARTNERSHIP: {
			return update(state, { $set: initialState });
		}

		default:
			return state;
	}
};

export default reducer;
