import { CollectionNames } from '@glissandoo/lib/helpers/collections';
import firebase from 'firebase/app';
import { partnerDB } from 'functions/partner';
import { partnershipDB } from 'functions/partnership';
import { planDB } from 'functions/plan';
import pick from 'lodash/pick';
import { getUser } from 'modules/auth/selectors';
import {
	getFirestoreListHandler,
	getFirestoreViewHandler,
	ModuleListActions,
	ModuleViewActions,
} from 'modules/firestore';
import { ApplicationState } from 'modules/root';
import { ThunkAction } from 'redux-thunk';
import {
	PartnerDocData,
	PartnerParams,
	PartnershipAction,
	PartnershipDocData,
	PartnershipParams,
	PartnershipTypes,
	PlanDocData,
	PlanParams,
} from './types';

type ThunkResult<R> = ThunkAction<
	R,
	ApplicationState,
	undefined,
	PartnershipAction
>;

const formatPartnerList = (
	doc: firebase.firestore.DocumentSnapshot,
	state: ApplicationState
): PartnerDocData => {
	const model = partnerDB.model(doc);
	const params = Object.values(PartnerParams);
	return pick(model, params);
};

export const fetchPartners =
	(action: ModuleListActions): ThunkResult<void> =>
	async (dispatch, getState) => {
		const state = getState();

		const user = getUser(state);

		if (!user || !user.partnershipId) {
			return Promise.resolve();
		}

		const endpoint = firebase
			.firestore()
			.collection(CollectionNames.Partnership)
			.doc(user.partnershipId)
			.collection(CollectionNames.PartnershipPartners)
			.orderBy('createdAt', 'desc');

		dispatch(
			getFirestoreListHandler({
				action,
				list: state.partnership.partners,
				type: PartnershipTypes.UPDATE_LIST_PARTNERS,
				endpoint,
				formatItem: formatPartnerList,
				itemsByPage: 500,
			})
		);

		return Promise.resolve();
	};

const formatPlansList = (
	doc: firebase.firestore.DocumentSnapshot,
	state: ApplicationState
): PlanDocData => {
	const model = planDB.model(doc);
	const params = Object.values(PlanParams);
	return pick(model, params);
};

export const fetchPlans =
	(action: ModuleListActions): ThunkResult<void> =>
	async (dispatch, getState) => {
		const state = getState();

		const user = getUser(state);

		if (!user || !user.partnershipId) {
			return Promise.resolve();
		}

		const endpoint = firebase
			.firestore()
			.collection(CollectionNames.Partnership)
			.doc(user.partnershipId)
			.collection(CollectionNames.PartnershipPlans)
			.where('deletedAt', '==', null)
			.orderBy('createdAt', 'desc');

		dispatch(
			getFirestoreListHandler({
				action,
				list: state.partnership.plans,
				type: PartnershipTypes.UPDATE_LIST_PLANS,
				endpoint,
				formatItem: formatPlansList,
				itemsByPage: 500,
			})
		);

		return Promise.resolve();
	};

const formatPartnershipView = (
	doc: firebase.firestore.DocumentSnapshot,
	state: ApplicationState
): PartnershipDocData => {
	const model = partnershipDB.model(doc);
	const params = Object.values(PartnershipParams);
	return pick(model, params);
};

export const fetchPartnership =
	(action: ModuleViewActions): ThunkResult<void> =>
	(dispatch, getState) => {
		const state = getState();

		const user = getUser(state);

		if (!user || !user.partnershipId) {
			return Promise.resolve();
		}

		const endpoint = firebase
			.firestore()
			.collection(CollectionNames.Partnership)
			.doc(user.partnershipId);

		console.log('fetch', user.partnershipId);

		dispatch(
			getFirestoreViewHandler({
				action,
				view: state.partnership.view,
				type: PartnershipTypes.SET_VIEW_PARTNERSHIP,
				endpoint,
				formatView: formatPartnershipView,
			})
		);

		return Promise.resolve();
	};
