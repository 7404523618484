import { AuthUserDocData } from 'modules/auth/types';
import { ApplicationState } from 'modules/root';
import { createContext, FC, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

export const AuthContext = createContext<AuthUserDocData | null>(null);

export const AuthProvider: FC = ({ children }) => {
	const { user } = useSelector((state: ApplicationState) => state.auth);

	if (user.loading) {
		return null;
	}

	if (user.error || !user.data) {
		return <Redirect to={{ pathname: '/signin' }} />;
	}

	return (
		<AuthContext.Provider value={user.data}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === null) {
		throw new Error(`useAuth must be used within a AuthProvider`);
	}
	return context;
};
