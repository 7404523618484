import { LanguagesTypes } from '@glissandoo/lib/lang';
import antdES from 'antd/lib/locale/es_ES';
import esDateLocale from 'date-fns/locale/es';
import set from 'lodash/set';
import messages from '../locales/es.json';

// antdSA.DatePicker?.lang.locale

const esLang = {
	messages,
	// Al utilizar el data-fns en DatePicker no tiene bien asignadas los nombres de los locales. Intenta cargar es_ES y no lo encuentra
	antd: set(antdES, 'DatePicker.lang.locale', 'es'),
	locale: LanguagesTypes.ES,
	dateFns: esDateLocale,
};

export default esLang;
