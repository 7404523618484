import { FbFunctionName } from '@glissandoo/lib/functions';
import { PartnershipPlanFbFunctionsTypes as Types } from '@glissandoo/lib/functions/partnershipPlan';
import PartnershipPlan from '@glissandoo/lib/models/Partnership/Plan';
import { DocumentData, DocumentSnapshot } from '@google-cloud/firestore';
import firebase from 'firebase/app';
import { callFunction } from './index';

const model = (doc: firebase.firestore.DocumentSnapshot) =>
	new PartnershipPlan(doc as unknown as DocumentSnapshot<DocumentData>);

const add = (data: Types.AddParams) =>
	callFunction<Types.AddParams, Types.AddResult>(
		data,
		FbFunctionName.PartnershipPlanAdd
	);

const edit = (data: Types.EditParams) =>
	callFunction<Types.EditParams, Types.EditResult>(
		data,
		FbFunctionName.PartnershipPlanEdit
	);

const remove = (data: Types.RemoveParams) =>
	callFunction<Types.RemoveParams, Types.RemoveResult>(
		data,
		FbFunctionName.PartnershipPlanRemove
	);

export const planDB = {
	model,
	add,
	edit,
	remove,
};
