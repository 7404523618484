import {
	LineChartOutlined,
	TeamOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Typography } from 'antd';
import { AVATAR_GROUP_DEFAULT } from 'helpers/constants';
import { getPartnership } from 'modules/partnership/selectors';
import { ApplicationState } from 'modules/root';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

interface Props {
	collapsed: boolean;
}

const Sidebar: React.FC<Props> = ({ collapsed }) => {
	const location = useLocation();
	const history = useHistory();

	const partnership = useSelector((state: ApplicationState) =>
		getPartnership(state)
	);

	const selectedKey = useMemo(() => {
		const { pathname } = location;
		return pathname.split('/')[1];
	}, [location]);

	return (
		<Layout.Sider
			trigger={null}
			collapsible
			collapsed={collapsed}
			className="min-h-screen"
		>
			<div className="flex flex-col justify-center items-center my-8 mx-4">
				<img
					className="rounded mb-4"
					width={collapsed ? 32 : 64}
					height={collapsed ? 32 : 64}
					src={
						partnership
							? partnership.photoURL
							: AVATAR_GROUP_DEFAULT
					}
					alt="logo"
				/>
				{!partnership || collapsed ? null : (
					<div className="px-4 text-center">
						<Typography.Title
							level={4}
							type="secondary"
							style={{ color: 'white' }}
							ellipsis={{ rows: 3 }}
						>
							{partnership.displayName}
						</Typography.Title>
					</div>
				)}
			</div>
			<Menu
				theme="dark"
				mode="inline"
				defaultSelectedKeys={['partners']}
				selectedKeys={[selectedKey]}
				onClick={({ key }) => history.push(`/${key}`)}
			>
				<Menu.Item key="partners" icon={<LineChartOutlined />}>
					<FormattedMessage id="sidebar.partners" />
				</Menu.Item>
				<Menu.Item key="plans" icon={<UserOutlined />}>
					<FormattedMessage id="sidebar.plans" />
				</Menu.Item>
				<Menu.Item key="payments" icon={<TeamOutlined />}>
					<FormattedMessage id="sidebar.payments" />
				</Menu.Item>
			</Menu>
		</Layout.Sider>
	);
};

export default Sidebar;
