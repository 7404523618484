import { Skeleton } from 'antd';

const SkeletonLoading = () => {
	return (
		<div className="layout-content">
			<div className="container p-0">
				<Skeleton.Button
					active
					size="large"
					style={{ margin: '10px 0 30px', width: '200px' }}
				/>
				<Skeleton active title={false} paragraph={{ rows: 3 }} />
			</div>
		</div>
	);
};

export default SkeletonLoading;
