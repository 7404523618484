import User from '@glissandoo/lib/models/User';
import { DocumentData, DocumentSnapshot } from '@google-cloud/firestore';
import firebase from 'firebase/app';

const model = (doc: firebase.firestore.DocumentSnapshot) =>
	new User(doc as unknown as DocumentSnapshot<DocumentData>);

export const userDB = {
	model,
};
