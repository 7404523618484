import { Spin } from 'antd';
import { FC, lazy, Suspense } from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { useAuth } from 'router/AuthContext';
import NoMatch from 'routes/NoMatch';
import { PartnershipProvider } from 'routes/PartnershipContext';

const Partners = lazy(() => import('routes/Partner/List'));
const Partner = lazy(() => import('routes/Partner/View'));
const Payments = lazy(() => import('routes/Payments'));
const Plans = lazy(() => import('routes/Plans'));

const DashboardRoute: FC<RouteProps> = ({ location, ...other }) => {
	const { partnershipId } = useAuth();

	if (!partnershipId) {
		return <Redirect to={{ pathname: '/signin' }} />;
	}

	return (
		<Route
			{...other}
			render={() => (
				<PartnershipProvider id={partnershipId}>
					<Suspense fallback={<Spin />}>
						<Switch>
							<Route
								exact
								path="/partners"
								component={Partners}
							/>
							<Route
								exact
								path="/partners/:id"
								component={Partner}
							/>
							<Route
								exact
								path="/payments"
								component={Payments}
							/>
							<Route exact path="/plans" component={Plans} />
							<Route
								exact
								path="/"
								render={() => <Redirect to="/partners" />}
							/>
							<Route component={NoMatch} />
						</Switch>
					</Suspense>
				</PartnershipProvider>
			)}
		/>
	);
};

export default DashboardRoute;
