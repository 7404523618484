export const colors = {
	white: '#FFFFFF',
	black: '#000000',
	primary: '#66c94a',
	secondary: '#ff5f2e',
	dark: '#004e66',
	light: '#e1eef6',
	green: '#56A902',
	yellow: '#f9c00c',
	grayLight: '#E0E0E0',
	grayLightLight: '#F2F2F2',
	gray: '#797979',
	blue: '#01AADA',
	red: '#E53A40',
	orangeLightTint: '#ff8f6c',
	noWhite: '#fffdfc',
	lessNoWhite: '#f9f1ee',
};
