import { LanguagesTypes } from '@glissandoo/lib/lang';
import antdDe from 'antd/lib/locale/de_DE';
import deDateLocale from 'date-fns/locale/de';
import { set } from 'lodash';
import messages from '../locales/de.json';

const DeLang = {
	messages,
	antd: set(antdDe, 'DatePicker.lang.locale', 'de'),
	locale: LanguagesTypes.DE,
	dateFns: deDateLocale,
};

export default DeLang;
