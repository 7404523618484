import { AVATAR_GROUP_DEFAULT, AVATAR_USER_DEFAULT } from 'helpers/constants';
import { colors } from 'helpers/styles';
import React, { useEffect, useState } from 'react';
import './styles.less';

interface Props {
	imageURL?: string;
	name?: string;
	size: 'xs' | 's' | 'ms' | 'm' | 'l' | 'xl' | 'xxl';
	onClick?: () => void;
	className?: string;
}

const Avatar = ({ imageURL, name, size, onClick, className }: Props) => {
	const [error, setError] = useState<boolean>(false);

	useEffect(() => setError(false), [imageURL, name]);

	const sizes = {
		xs: 24,
		s: 32,
		ms: 40,
		m: 48,
		l: 64,
		xl: 100,
		xxl: 150,
	};

	/* tslint:disable:no-bitwise */
	const stringToColour = (str: string) => {
		let hash = 0;
		for (let i = 0; i < str.length; i = i + 1) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		let colour = '#';
		for (let j = 0; j < 3; j = j + 1) {
			const value = (hash >> (j * 8)) & 0xff;
			colour += `00${value.toString(16)}`.substr(-2);
		}
		return colour;
	};
	/* tslint:enable:no-bitwise */

	if (
		!imageURL ||
		error ||
		imageURL === AVATAR_GROUP_DEFAULT ||
		imageURL === AVATAR_USER_DEFAULT
	) {
		if (!name) {
			return (
				<div
					className={`avatar ${size} avatar-default ${className}`}
					style={{
						backgroundColor: 'white',
						borderColor: 'gray',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				/>
			);
		}

		const color = stringToColour(name.trim());
		const innerName = name.trim();

		return (
			<div
				className={`avatar ${size} avatar-default ${className}`}
				onClick={onClick}
				style={{
					backgroundColor: color,
					borderColor: color,
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				{innerName.length > 1 ? (
					<h4
						className={`avatar-text-${size}`}
						style={{
							fontFamily: 'Nunito',
							color: colors.white,
						}}
					>
						{innerName[0].toUpperCase()}
						{innerName[1].toLowerCase()}
					</h4>
				) : null}
			</div>
		);
	}

	let _image = imageURL;

	if (imageURL.includes('https://graph.facebook.com')) {
		const _size = sizes[size];
		_image = imageURL
			.split('?')[0]
			.concat(`?width=${_size}&height=${_size}`);
	}

	return (
		<img
			className={`avatar ${size} ${className}`}
			width={sizes[size]}
			height={sizes[size]}
			src={_image}
			alt={name}
			title={name}
			onError={() => setError(true)}
		/>
	);
};

export default React.memo(Avatar);
