import { FbFunctionName } from '@glissandoo/lib/functions';
import { PartnershipFbFunctionsTypes as Types } from '@glissandoo/lib/functions/partnership';
import Partnership from '@glissandoo/lib/models/Partnership';
import { DocumentData, DocumentSnapshot } from '@google-cloud/firestore';
import firebase from 'firebase/app';
import { callFunction } from './index';

const model = (doc: firebase.firestore.DocumentSnapshot) =>
	new Partnership(doc as unknown as DocumentSnapshot<DocumentData>);

export const getBalance = (data: Types.GetBalanceParams) =>
	callFunction<Types.GetBalanceParams, Types.GetBalanceResult>(
		data,
		FbFunctionName.PartnershipGetBalance
	);

export const partnershipDB = {
	model,
	getBalance,
};
