import { FbFunctionName } from '@glissandoo/lib/functions';
import { PartnerFbFunctionsTypes as Types } from '@glissandoo/lib/functions/partner';
import PartnershipPartner from '@glissandoo/lib/models/Partnership/Partner';
import { DocumentData, DocumentSnapshot } from '@google-cloud/firestore';
import firebase from 'firebase/app';
import { callFunction } from 'functions';

const model = (doc: firebase.firestore.DocumentSnapshot) =>
	new PartnershipPartner(doc as unknown as DocumentSnapshot<DocumentData>);

const add = (data: Types.CreateParams) =>
	callFunction<Types.CreateParams, Types.CreateResult>(
		data,
		FbFunctionName.PartnerAdd
	);

const edit = (data: Types.EditParams) =>
	callFunction<Types.EditParams, Types.EditResult>(
		data,
		FbFunctionName.PartnerEdit
	);

const remove = (data: Types.RemoveParams) =>
	callFunction<Types.RemoveParams, Types.RemoveResult>(
		data,
		FbFunctionName.PartnerRemove
	);

const validateIBAN = (data: Types.ValidateIBANParams) =>
	callFunction<Types.ValidateIBANParams, Types.ValidateIBANResult>(
		data,
		FbFunctionName.PartnerValidateIBAN
	);

const sendVerification = (data: Types.SendVerificationParams) =>
	callFunction<Types.SendVerificationParams, Types.SendVerificationResult>(
		data,
		FbFunctionName.PartnerSendVerification
	);

export const partnerDB = {
	model,
	add,
	edit,
	remove,
	validateIBAN,
	sendVerification,
};
