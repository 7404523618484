import Partnership from '@glissandoo/lib/models/Partnership';
import PartnershipPartner from '@glissandoo/lib/models/Partnership/Partner';
import PartnershipPlan from '@glissandoo/lib/models/Partnership/Plan';
import { FetchListState, FetchViewState } from 'modules/firestore';
import { Action } from 'redux';

export enum PartnershipTypes {
	SET_VIEW_PARTNERSHIP = 'SET_VIEW_PARTNERSHIP',
	UPDATE_LIST_PARTNERS = 'UPDATE_LIST_PARTNERS',
	UPDATE_LIST_PLANS = 'UPDATE_LIST_PLANS',
	CLEAR_PARTNERSHIP = 'CLEAR_PARTNERSHIP',
}

export enum PartnershipParams {
	Id = 'id',
	Displayname = 'displayName',
	PhotoURL = 'photoURL',
}
export type PartnershipDocData = Pick<Partnership, PartnershipParams>;

export enum PartnerParams {
	Id = 'id',
	Displayname = 'displayName',
	SubscriptionIds = 'subscriptionIds',
	Status = 'status',
	CreatedAt = 'createdAt',
}
export type PartnerDocData = Pick<PartnershipPartner, PartnerParams>;

export enum PlanParams {
	Id = 'id',
	Name = 'name',
	Description = 'description',
	Price = 'price',
	DisplayPrice = 'displayPrice',
	Schedule = 'schedule',
	GetFirstPaymentDate = 'getFirstPaymentDate',
}
export type PlanDocData = Pick<PartnershipPlan, PlanParams>;

interface SetViewPartnership extends Action {
	type: PartnershipTypes.SET_VIEW_PARTNERSHIP;
	payload: {
		id: string;
		value: Partial<FetchViewState<PartnershipDocData>>;
	};
}

interface UpdateListPlansPartnershipAction extends Action {
	type: PartnershipTypes.UPDATE_LIST_PLANS;
	payload: Partial<FetchListState<PlanDocData>>;
}

interface UpdateListPartnersPartnershipAction extends Action {
	type: PartnershipTypes.UPDATE_LIST_PARTNERS;
	payload: Partial<FetchListState<PartnerDocData>>;
}

interface ClearPartnershipAction extends Action {
	type: PartnershipTypes.CLEAR_PARTNERSHIP;
}

export type PartnershipAction =
	| ClearPartnershipAction
	| UpdateListPlansPartnershipAction
	| UpdateListPartnersPartnershipAction
	| SetViewPartnership;
