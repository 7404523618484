import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import { useMount } from 'react-use';

interface Props
	extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	name: string;
	title: string;
}

const LayoutContentDashboard: FC<Props> = ({
	name,
	children,
	className,
	title,
	...other
}) => {
	useMount(() => {
		document.title = `Glissandoo  - ${title}`;
	});
	return (
		<div
			id={name}
			className="container max-w-7xl mx-auto pb-40 px-8 pt-8 bg-gray-100"
			{...other}
		>
			{children}
		</div>
	);
};

export default LayoutContentDashboard;
