import { LanguagesTypes } from '@glissandoo/lib/lang';
import antdGl from 'antd/lib/locale/gl_ES';
import glDateLocale from 'date-fns/locale/gl';
import set from 'lodash/set';
import messages from '../locales/gl.json';

const glLang = {
	messages,
	// Al utilizar el data-fns en DatePicker no tiene bien asignadas los nombres de los locales. Intenta cargar es_CA y no lo encuentra
	antd: set(antdGl, 'DatePicker.lang.locale', 'gl'),
	locale: LanguagesTypes.GL,
	dateFns: glDateLocale,
};

export default glLang;
