import { defaultLocale, LanguagesTypes } from '@glissandoo/lib/lang';
import { Locale as LocaleAntd } from 'antd/lib/locale-provider';
import { Locale as LocaleFNS } from 'date-fns';
import CaLang from './entries/ca';
import DeLang from './entries/de';
import EnLang from './entries/en';
import EsLang from './entries/es';
import GlLang from './entries/gl';

export interface LocaleLang {
	messages: Record<string, string>;
	antd: LocaleAntd;
	locale: LanguagesTypes;
	dateFns: LocaleFNS;
}

const locales: { [key in LanguagesTypes]: LocaleLang } = {
	en: EnLang,
	es: EsLang,
	ca: CaLang,
	gl: GlLang,
	de: DeLang,
};

export const getLocale = (lang?: LanguagesTypes) => {
	if (!lang) return locales[defaultLocale];
	if (!locales[lang]) return locales[defaultLocale];
	return locales[lang];
};

export const supportedLocales = Object.values(LanguagesTypes);
export default locales;
