import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { configureStore, history } from 'modules/store';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import Router from 'router';
import LanguageProvider from './lang';

!firebase.apps.length
	? firebase.initializeApp({
			apiKey: process.env.REACT_APP_API_KEY,
			authDomain: process.env.REACT_APP_AUTH_DOMAIN,
			databaseURL: process.env.REACT_APP_DATABASE_URL,
			projectId: process.env.REACT_APP_PROJECT_ID,
			storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
			messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
			appId: process.env.REACT_APP_APP_ID,
			measurementId: process.env.REACT_APP_MEASUREMENT_ID,
	  })
	: firebase.app();

const store = configureStore();

const App = () => (
	<CookiesProvider>
		<Provider store={store}>
			<LanguageProvider>
				<Router history={history} />
			</LanguageProvider>
		</Provider>
	</CookiesProvider>
);

export default App;
