import Content from 'layouts/Dashboard/Content';
import './styles.less';

const NoMatch = () => (
	<Content name="notfound" title="Página no encontrada">
		<div>
			<h1>404</h1>
			<h2>¡Vaya! Esta página podría no existir</h2>
			<p>
				Lo sentimos pero la página que estás buscando no existe,
				<br />
				ha sido eliminada o ha cambiado de nombre temporalmente
			</p>
		</div>
	</Content>
);

export default NoMatch;
