import { ApplicationState } from 'modules/root';

export const getPartnership = ({ partnership }: ApplicationState) => {
	console.log(partnership);
	return partnership.view.data;
};

export const getPlans = ({ partnership }: ApplicationState) => {
	return partnership.plans.items;
};

export const getPlan = (state: ApplicationState, id: string) => {
	const plans = getPlans(state);
	return plans.find((plan) => plan.id === id);
};

export const getPartners = ({ partnership }: ApplicationState) => {
	return partnership.partners.items;
};
