import update from 'immutability-helper';
import { Reducer } from 'redux';
import { AppActions, AppState, AppTypes } from './types';

const initState: AppState = {
	lang: undefined,
};

export const appReducer: Reducer<AppState, AppActions> = (
	state: AppState = initState,
	action
) => {
	switch (action.type) {
		case AppTypes.SET_LANG: {
			return {
				...state,
				lang: action.payload,
			};
		}
		case AppTypes.CLEAR_APP:
			// Mantenemos el idioma
			const lang = state.lang;
			const _state = update(state, { $set: initState });
			return {
				..._state,
				lang,
			};

		default:
			return state;
	}
};

export default appReducer;
