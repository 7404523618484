import { Typography } from 'antd';
import Avatar from 'common/Avatar';
import { useAuth } from 'router/AuthContext';
import LogoutButton from './Logout';

const UserMenu = () => {
	const auth = useAuth();

	return (
		<div className="flex items-center">
			<Avatar
				imageURL={auth.photoURL}
				name={auth.displayName}
				size="ms"
				className="mr-4"
			/>
			<div className="leading-5 mt-1">
				<Typography.Text>{auth.displayName}</Typography.Text>
				<br />
				<LogoutButton />
			</div>
		</div>
	);
};

export default UserMenu;
